import { omit } from 'lodash';
import createAction from '../../common/services/create-action.js';

export const UPDATE_FORUM_DATA_REQUEST = 'forumData/UPDATE_REQUEST';
export const UPDATE_FORUM_DATA_SUCCESS = 'forumData/UPDATE_SUCCESS';
export const UPDATE_FORUM_DATA_FAILURE = 'forumData/UPDATE_FAILURE';

export const updateForumDataRequest = createAction(UPDATE_FORUM_DATA_REQUEST);
export const updateForumDataSuccess = createAction(UPDATE_FORUM_DATA_SUCCESS);
export const updateForumDataFailure = createAction(UPDATE_FORUM_DATA_FAILURE);

const OMIT_FIELDS = ['_id', 'communityId', 'registrationOpened', 'registrationManuallyApproved', 'baseUrl', 'sectionUrl'];

export function updateForumData(data = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(updateForumDataRequest());
    const promise = request.patch('/forums/this', omit(data, OMIT_FIELDS));

    return promise
      .then(post => dispatch(updateForumDataSuccess(post)))
      .catch(() => dispatch(updateForumDataFailure()))
      .then(() => promise);
  };
}
