import createAction from '../services/create-action';
import { isEqual, noop } from 'lodash';

export const FETCH_USER_REQUEST = 'posts/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'posts/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'posts/FETCH_USER_FAILURE';

export const fetchUserRequest = createAction(FETCH_USER_REQUEST);
export const fetchUserSuccess = createAction(FETCH_USER_SUCCESS);
export const fetchUserFailure = createAction(FETCH_USER_FAILURE);

export function fetchUser({ siteMemberId }) {
  return (dispatch, getState, { request, sentry }) => {
    dispatch(fetchUserRequest());

    const promise = request(`/users/site-member/${siteMemberId}`);
    const promise2 = request(`/users/site-member/current`);

    return Promise.all([promise, promise2.catch(noop)]).then(
      ([body, body2]) => {
        if (isEqual(body, body2)) {
          dispatch(fetchUserSuccess(body2));
        } else {
          sentry.setExtraContext({ byId: body, current: body2 });
          sentry.captureMessage('CURRENT !== fetch by Id');
          dispatch(fetchUserSuccess(body));
        }
      },
      () => dispatch(fetchUserFailure()),
    ); // we don't returned original promise, because this fails in editor when forum is from template but not yet provisioned
  };
}
