import { get } from 'lodash';
import { SENTRY_VIEWER_SCRIPT_DSN } from '../constants/sentry-viewer-dsn';
import { getStaticsVersionFromUrl } from './statics-version';

export const ENVIRONMENT = {
  APP: 'App',
  RPW: 'RPW',
};

export const normalizeError = error => {
  if (error instanceof Response && error.constructor.name !== 'Error') {
    return Error(`Failed to fetch ${error.url} with status ${error.status} - ${error.statusText}`);
  }
  return error;
};

export const decorateActionsWithLogger = (actions, sentry) => {
  const decoratedActions = {};
  const decorate = (action, key) => (...args) => {
    const captureToSentry = e =>
      sentry.captureException(normalizeError(e), {
        extra: { action: key },
        tags: { capturedBy: 'action monitor' },
      });
    try {
      const response = action(...args);
      if (response instanceof Promise) {
        response.catch(error => {
          if (error && error.status !== 200 && error.status !== 500) {
            return;
          }

          captureToSentry(error);
        });
      }
      return response;
    } catch (error) {
      captureToSentry(error);
    }
  };

  Object.keys(actions).map(key => {
    const action = actions[key];
    decoratedActions[key] = decorate(action, key);
  });

  return decoratedActions;
};

export const createSentryMonitor = ({ platformAPIs, isSSR, environment, appParams }) => {
  const url = get(appParams, 'baseUrls.staticsBaseUrl', '');
  const version = getStaticsVersionFromUrl(url);

  const raven = platformAPIs.monitoring.createMonitor(SENTRY_VIEWER_SCRIPT_DSN, data => {
    data.environment = isSSR ? `${environment}-Worker-SSR` : `${environment}-Worker-CSR`;
    return data;
  });

  if (raven && raven.setRelease) {
    raven.setRelease(version);
  }

  return raven;
};
